import React, { useEffect, useState } from 'react'
import './Styles/servicesstyle.css'
import ServiceCard from '../Comp/ServiceCard'


export default function Service() {
  const [SAV, setSAV] = useState([]);
  const [Agent, setAgent] = useState([]);

  useEffect(() => {
    const fetchSAVData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_URL_BASE+'/SAV', {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setSAV(data);
        } else {
          console.error("Error receiving Panne data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching Panne data:", error);
      }
    };
  
    fetchSAVData();
  }, []);
  useEffect(() => {
    const fetchAgentData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_URL_BASE+'/Agent', {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setAgent(data);
        } else {
          console.error("Error receiving Panne data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching Panne data:", error);
      }
    };
  
    fetchAgentData();
  }, []);
  return (
    <div className='Service-page-content'>
        <h2>Service Apres Vente</h2>
        <p>Découvrez la liste des différents points SAV que Stream met à la disposition de ses Clients</p>
        <section className='cards'>
          <div className='section-title'>
            <h4>SAV International</h4> 
          </div>
          <div className='cards-section'>
            <ServiceCard title='Espagne' 
              location='C/Conrado del Campo, Nave 4, Pol. Ind. Trevenez, 29590, Malaga (Malaga)'
              number='+34 952 179 479'
            />
           
           <ServiceCard title='Portugal' 
              location='LetMeRepair Spain Sucursal Portugal Parque Industrial do Cotai Av. Salgueiro Maia n 1025 Armazem 5, Aboboda'
              number='+351 211 309 453'
            />

            <ServiceCard title='Italy' 
              location='inspeedy S.R.L via lodovico settala 3 20124 Milano (MI) - IT'
              number='+39 199 240 668'
            />
          
          </div>
        </section>
        <section className='cards'>
          <div className='section-title'>            
            <h4>SAV Local</h4>            
          </div>
          <div className='cards-section'>
            {SAV?.map((sav, index) => (
              <ServiceCard
                key={index}
                title={sav.Region}
                location={sav.Localisation}
                number={sav.Telephone}
              />
            ))}
            {Agent?.map((sav, index) => (
              <ServiceCard
                key={index}
                title={sav.Region}
                location={sav.Adresse}
                number={sav.Telephone}
              />
            ))}
          </div>           
        </section>       
    </div>
  )
}

